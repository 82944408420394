import React from 'react'
import Heading from '../components/typograph/Heading'
import Text from '../components/typograph/Text'
import Layout from '../components/layout/Layout'
import PageContainer from '../components/layout/PageContainer'
import Hero from '../components/hero/Hero'
import IlustratedContent from '../components/ilustratedParagraph/IlustratedContent'
import styled from 'styled-components'
import { LIGHT_GRAY, ORANGE } from '../constants/Colors'

import aboutUsImage from '../images/Banner-SobreNos-Desk.jpg'
import aboutUsImageMobile from '../images/Banner-SobreNos-Mobile.jpg'
import ComprValores1 from '../images/ComprValores-01.png'
import ComprValores2 from '../images/ComprValores-02.png'
import ComprValores3 from '../images/ComprValores-03.png'
import ComprValores4 from '../images/ComprValores-04.png'

import imgmosaico1 from '../images/imgmosaico-01.jpg'
import imgmosaico2 from '../images/imgmosaico-02.jpg'
import imgmosaico3 from '../images/imgmosaico-03.jpg'
import GatsbyLink from 'gatsby-link'

const GrayContainer = styled.div`
  background-color: ${LIGHT_GRAY};
  padding-bottom: 64px;
  z-index: -1;
  width: 100%;
  left: 0;
  padding-top: 40px;
`

const MosaicContainer = styled.div`
  padding: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: center;

    & span {
      width: 80%;
    }
  }

  & img {
    width: 100%;
  }
`

const IlustrationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  max-width: 1450px;
  margin: 0 auto;

  @media (max-width: 1044px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: 32px;
  margin-top: 10px;

  & .title {
    text-align: right;
    margin-right: 80px;
    max-width: 300px;
  }

  @media (max-width: 1044px) {
    flex-wrap: wrap;
    justify-content: center;

    & .title {
      margin-right: 0px;
      text-align: center;
      max-width: 100%;
    }
  }
`

const AboutUs = () => (
  <Layout
    title="Sobre nós - HDScience"
    meta={[
      {
        name: 'description',
        content:
          'A HDScience possui cursos e mini-cursos com o diferencial de que, além do desenvolvimento da prática clínica com aplicação de conceitos, proveem aos alunos grande aprendizado de cunho científico.',
      },
      {
        name: 'keywords',
        content:
          'homeopatia, veterinária, cromoterapia, curso, cursos, mini-curso, mini-cursos',
      },
      { property: 'og:url', content: 'http://www.hdscience.com.br/sobre-nos' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Sobre nós - HDScience' },
      {
        property: 'og:description',
        content:
          'A HDScience possui cursos e mini-cursos com o diferencial de que, além do desenvolvimento da prática clínica com aplicação de conceitos, proveem aos alunos grande aprendizado de cunho científico.',
      },
    ]}
  >
    <Hero image={aboutUsImage} mobileImage={aboutUsImageMobile}>
      <Heading
        type="h1"
        style={{ color: '#fff', textAlign: 'center', paddingTop: 80 }}
      >
        A HD Science
      </Heading>
    </Hero>
    <PageContainer>
      <TextContainer>
        <div className="title">
          <Heading type="h3">
            A constante busca pela excelência em homeopatia e outras ciências da
            saúde.
          </Heading>
        </div>
        <div style={{ maxWidth: '730px', paddingTop: 20 }}>
          <Text type="p">
            Após 25 anos de dedicação à veterinária e quase 20 anos dedicados à
            homeopatia e aos animais silvestres, a{' '}
            <strong>
              <GatsbyLink
                to="/profissionais/"
                style={{ textDecoration: 'none', color: ORANGE }}
              >
                Profª Dra. Cidéli Coelho
              </GatsbyLink>
              ,{' '}
            </strong>
            que rodou o mundo passando e coletando informações sobre altas
            diluições, tomou uma grande decisão: iniciar, em conjunto de outro
            experiente profissional clínico - o{' '}
            <strong>
              <GatsbyLink
                to="/profissionais/"
                style={{ textDecoration: 'none', color: ORANGE }}
              >
                Dr. Adalberto Von Ancken{' '}
              </GatsbyLink>
            </strong>
            -, o seu próprio curso de homeopatia, voltado a veterinários e
            também farmacêuticos.
            <br />
            <br />
            <b>
              Juntos e compartilhando dos mesmos anseios eles deram vida à HD
              Science
            </b>{' '}
            que possui cursos e mini-cursos com o diferencial de que, além do
            desenvolvimento da prática clínica com aplicação de conceitos,
            proveem aos alunos grande aprendizado de cunho científico!
          </Text>
        </div>
      </TextContainer>
    </PageContainer>
    <GrayContainer>
      <Heading type="h2" style={{ textAlign: 'center' }}>
        Compromissos & Valores
      </Heading>
      <IlustrationsContainer>
        <IlustratedContent image={ComprValores4}>
          Tornar a ciência acessível, além de uma realidade diária.
        </IlustratedContent>
        <IlustratedContent image={ComprValores3}>
          Promover o estudo e pesquisa científica em homeopatia e outras áreas,
          agregando valores éticos consolidados e inovadores.
        </IlustratedContent>
        <IlustratedContent image={ComprValores2}>
          Qualidade, comprometimento e inovação naquilo que fazemos como
          veterinários e professores.
        </IlustratedContent>
        <IlustratedContent image={ComprValores1}>
          Agir em prol e em conjunto de nossos alunos buscando estreitar esse
          relacionamento dia após dia.
        </IlustratedContent>
      </IlustrationsContainer>
    </GrayContainer>
    <PageContainer style={{ marginBottom: 40 }}>
      <MosaicContainer>
        <span>
          <img src={imgmosaico1} alt="Fotos do Curso" />
        </span>
        <span>
          <img src={imgmosaico2} alt="Fotos do Curso" />
        </span>
        <span>
          <img src={imgmosaico3} alt="Fotos do Curso" />
        </span>
      </MosaicContainer>
    </PageContainer>
  </Layout>
)

export default AboutUs
